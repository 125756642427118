import axios from 'axios';

export default {
  methods: {
    scrollToHash() {
      const hashId = this.$route.hash.substr(1);
      if (import.meta.client && hashId && document.getElementById(hashId)) {
        document.getElementById(hashId).scrollIntoView();
      }
      return false;
    },
    filterCallback() {
      if (!this.scrollToHash()) {
        this.$router.push({ query: { filter: this.selectedFilter } });
      }
      if (this.pageNumber) {
        this.pageNumber = 1;
      }
    },
    async setUpPage(getter, filter) {
      this.loading = true;
      try {
        const response = await axios.get(getter);
        this.filters = response.data.meta.filters;
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
      this.checkForFilter();
      await filter();
      this.loading = false;
      this.scrollToHash();
    },
    checkForFilter() {
      if (this.$route.query && this.$route.query.filter) {
        this.selectedFilter = this.$route.query.filter;
      } else {
        this.selectedFilter = this.filters[0].id;
      }
    },
    styleLinkified() {
      if (this.$el.querySelector('.linkified') !== null) {
        this.$el.querySelector('.linkified').style.color = this.getGlobals.info.links_color.hex;
      }
    },
    bottomVisible() {
      let visible = false;
      if (import.meta.client) {
        const y = window.scrollY;
        const v = document.documentElement.clientHeight;
        const h = document.documentElement.scrollHeight;
        const b = v + y >= h / 2;
        visible = b || h < v;
      }
      return visible;
    }
  }
};
